import React, { useEffect, useState } from 'react'

export function useSlider(data) {
  const [
    currentSlideIndex,
    setCurrentSlideIndex,
  ] = useState(0)
  const [currentTitle, setCurrentTitle] = useState(
    data[0].title + ' • TechStreamLab'
  )

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.location.hash) {
        let findData = data.find(x =>
          window.location.hash.includes(x.hash)
        )
        if (findData) {
          let index = data.findIndex(x =>
            window.location.hash.includes(x.hash)
          )
          setCurrentSlideIndex(index)
          setCurrentTitle(
            findData.title + ' • TechStreamLab'
          )
        }
      }
    }
  }, [])

  const slideForward = () => {
    let slideNum = 0
    if (currentSlideIndex === 0) {
      slideNum = data.length - 1
    } else {
      slideNum = currentSlideIndex - 1
    }
    let slideAtIndex = data[slideNum]
    if (slideAtIndex) {
      setCurrentSlideIndex(slideNum)
      setCurrentTitle(
        slideAtIndex.title + ' • TechStreamLab'
      )
      window.history.replaceState(
        null,
        null,
        '#' + slideAtIndex.hash
      )
    }
  }

  const slideBackward = () => {
    let slideNum = 0
    if (currentSlideIndex === data.length - 1) {
      slideNum = 0
    } else {
      slideNum = currentSlideIndex + 1
    }
    let slideAtIndex = data[slideNum]
    if (slideAtIndex) {
      setCurrentSlideIndex(slideNum)
      setCurrentTitle(
        slideAtIndex.title + ' • TechStreamLab'
      )
      window.history.replaceState(
        null,
        null,
        '#' + slideAtIndex.hash
      )
    }
  }

  return {
    currentSlideIndex,
    currentTitle,
    slideForward,
    slideBackward,
  }
}
