import React from 'react'
import * as Chakra from '@chakra-ui/react'

import PageInfo from './text-layouts/page-info'

const SubpageSlide = ({
  slideData,
  title,
  number,
  ...props
}) => {
  return (
    <Chakra.Box
      h="100vh"
      bg={`url(${slideData.img}) no-repeat center center fixed`}
      bgSize="cover"
      bgPos="center top"
      {...props}
    >
      <Chakra.Box
        h="100%"
        bg="rgba(0,0,0,.6)"
        color="#fff"
        pos="relative"
      >
        <Chakra.Flex
          pos="relative"
          px="max(4vw, 40px)"
          top="22vh"
          w="100%"
          flexDir="column"
          pb="max(120px, 6vw)"
        >
          <Chakra.Flex
            pos="relative"
            w="100%"
            color="#fff"
            d={{ base: 'none', lg: 'block' }}
          >
            <PageInfo
              numCurrent={number}
              numTotal="7"
              color="#fff"
              title="Industries"
              noHeader
            />
          </Chakra.Flex>
          <Chakra.Text
            mt={{
              base: '45px',
              lg: '15vh',
            }}
            mb="25px"
            textStyle="aktivGrotesk"
            fontSize={{
              base: '42px',
              lg: '75px',
              xl: '90px',
              '2xl': '120px',
            }}
            lineHeight="1"
          >
            {slideData.title}
          </Chakra.Text>
          <Chakra.Text
            textStyle="circularXX"
            fontWeight="300"
            fontSize={{
              base: '14px',
              lg: '22px',
              '2xl': '32px',
            }}
            w={'max(37%, 300px)'}
          >
            {slideData.description}
          </Chakra.Text>
        </Chakra.Flex>
      </Chakra.Box>
    </Chakra.Box>
  )
}

export default SubpageSlide
