import React from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import SubpageSlide from '../subpage-slide'
import * as Chakra from '@chakra-ui/react'

export function SlideTransition({
  title,
  number,
  currentSlideIndex,
  data,
}) {
  const duration = 0.4
  const variants = {
    initial: {
      opacity: 0,
      transition: { duration },
      position: 'absolute',
      width: '100%',
      overflowY: 'hidden',
    },
    enter: {
      opacity: 1,
      transition: {
        duration,
        delay: duration,
        when: 'beforeChildren',
      },
      overflowY: 'initial',
      position: 'relative',
    },
    exit: {
      opacity: 0,
      transition: { duration },
      position: 'absolute',
      width: '100%',
      overflowY: 'hidden',
    },
  }

  return (
    <Chakra.Box
      width={{
        base: '100%',
        lg: 'calc(100% - 7vw)',
      }}
      height="calc(100% - 203px)"
      position="relative"
    >
      <AnimatePresence>
        {data.map(
          (slideData, index) =>
            index === currentSlideIndex && (
              <motion.div
                key={currentSlideIndex}
                variants={variants}
                initial="initial"
                animate="enter"
                exit="exit"
              >
                <SubpageSlide
                  title={title}
                  number={number}
                  slideData={slideData}
                />
              </motion.div>
            )
        )}
      </AnimatePresence>
    </Chakra.Box>
  )
}
