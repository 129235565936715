import React from 'react'
import * as Chakra from '@chakra-ui/react'

import CompaniesOne from '../images/companies-one.jpg'
import CompaniesTwo from '../images/companies-two.jpg'
import CompaniesThree from '../images/companies-three.jpg'

import ArrowRightIcon from '../components/icons/arrow-right-icon'
import CustomCursorHover from '../components/custom-cursor-hover'
import { Helmet } from 'react-helmet'
import { useSlider } from '../hooks/useSlider'
import { SlideTransition } from '../components/animations/slide-transition'

const IndustriesPage = () => {
  const data = [
    {
      title: 'FanClub Sports Capital',
      description: `FanClub is a sports investment platform and community that democratizes access to investing in pro sports. We offer teams and LPs financing and liquidity while giving fans and investors a chance to own a piece of their favourite club.`,
      img: CompaniesOne,
      letter: 'A',
      hash: 'fanclub',
    },
    {
      title: `AIP
      LABS®`,
      description: `As the healthcare sector continues to
      evolve, Techstream Lab aims to invest in
      healthcare technologies that fundamentally
      transform healthcare practices and improve
      patient economics.`,
      img: CompaniesTwo,
      letter: 'B',
      hash: 'aip',
    },
  ]

  let {
    currentSlideIndex,
    currentTitle,
    slideForward,
    slideBackward,
  } = useSlider(data)

  return (
    <>
      <Helmet>
        <title>{currentTitle}</title>
      </Helmet>
      <SlideTransition
        number="5"
        title="Companies"
        currentSlideIndex={currentSlideIndex}
        data={data}
      />
      <Chakra.Flex
        h="16vh"
        paddingTop="5vh"
        pos="fixed"
        top={{ base: 'auto', lg: '0' }}
        bottom={{ base: '160px', lg: 'auto' }}
        right={{
          base: '50px',
          lg: 'calc(max(4vw, 40px) + max(120px, 6vw))',
        }}
        fontSize="18px"
        textTransform="uppercase"
        alignItems="center"
        textStyle="basisGrotesque"
        zIndex="500"
      >
        <CustomCursorHover
          d="flex"
          alignItems="center"
          onClick={() => window.history.back()}
          color="#fff"
        >
          <ArrowRightIcon
            style={{
              marginRight: '20px',
              transform: 'rotate(180deg)',
            }}
          />
          Back
        </CustomCursorHover>
      </Chakra.Flex>
      <Chakra.Flex
        pos="fixed"
        zIndex="5"
        bottom="0"
        h={{ base: '120px' }}
        bg="#15151D"
        w={{ base: '100%', lg: 'calc(100% - 7vw)' }}
        px="max(4vw, 40px)"
        alignItems="center"
        justify="space-between"
        color="#fff"
      >
        <Chakra.Flex>
          <CustomCursorHover
            mr="45px"
            py="15px"
            onClick={slideForward}
          >
            <ArrowRightIcon
              style={{ transform: 'rotate(180deg)' }}
            />
          </CustomCursorHover>
          <CustomCursorHover
            py="15px"
            onClick={slideBackward}
          >
            <ArrowRightIcon />
          </CustomCursorHover>
        </Chakra.Flex>

        <Chakra.Text
          fontSize={{
            '2xl': '32px',
          }}
          textStyle="basisGrotesque"
        >
          05—{data[currentSlideIndex].letter}
        </Chakra.Text>
      </Chakra.Flex>
    </>
  )
}

export default IndustriesPage
